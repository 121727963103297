<template>
  <div class="AiTest">
    <iframe
        class="child"
        src="http://ai.hs.3qlab.com/66614f3ae1a90a58924117b1"
    ></iframe>
  </div>
</template>

<script>

export default {
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    openTable(){
      this.$router.push('/Home')
    },
    openEquipment(){
      this.$router.push('/Equipment')
    },
  }
}


</script>


<style scoped lang="scss">
.AiTest{
  width: 100%;
  height: 100%;
  .child{
    width: 100%;
    height: 100%;
    border: none;
  }
}

button {
  margin-top: 10px;
  width: 100%;
  height: 36px;
  background-color: #187cff;
  border: none;
  border-radius: 3px;
  outline: none;
  color: #fff;
  font-size: 14px;
}
</style>